import { baseTailwindConfig } from '@front/shared';
import clsx from 'clsx';
import { cva } from 'cva';
import React from 'react';
import { useMedia } from 'react-use';

import { ReactComponent as IconArrowDown } from '../../../../public/icons/icon-arrow-down.svg';
import { ESolidTabSize, ESolidTabTheme, SolidTab } from '../../atoms/SolidTab';
import {
  ETitleCardBgAlign,
  ETitleCardBgPadding,
  TitleCardBg,
} from '../../atoms/TitleCardBg';
import { ETitlePartsSize } from '../../atoms/TitlePart';
import { Container } from '../../sections/Container';

export type TTitleBgCardsProps = {
  tabs: {
    name: string;
    hidden: boolean;
    cards: React.ComponentPropsWithoutRef<typeof TitleCardBg>[];
  }[];
  padding: ETitleCardBgPadding;
  align: ETitleCardBgAlign;
};

const gridCVA = cva('grid grid-cols-1 gap-4 md:grid-cols-2', {
  variants: {
    align: {
      [ETitleCardBgAlign.Center]: 'justify-center md:!flex md:flex-wrap',
      [ETitleCardBgAlign.Left]: 'justify-start',
      [ETitleCardBgAlign.Right]: 'justify-end',
      [ETitleCardBgAlign.Justify]: 'justify-between',
    },
    padding: {
      [ETitleCardBgPadding.None]: '',
      [ETitleCardBgPadding.Bottom]: 'pb-12 md:pb-16',
      [ETitleCardBgPadding.Top]: 'pt-12 md:pt-16',
    },
    size: {
      [ETitlePartsSize.XxxxxxL]: '',
      [ETitlePartsSize.XxxxxL]: '',
      [ETitlePartsSize.XxxxL]: '',
      [ETitlePartsSize.XxxL]: '',
      [ETitlePartsSize.XxL]: '',
      [ETitlePartsSize.Xl]: '',
      [ETitlePartsSize.L]: '',
      [ETitlePartsSize.Ml]: '',
      [ETitlePartsSize.Xs]: 'xl:grid-cols-3 2xl:grid-cols-4',
      [ETitlePartsSize.S]: 'xl:grid-cols-4',
      [ETitlePartsSize.M]: 'xl:grid-cols-3',
    },
  },
});

const TitleBgCards: React.FC<TTitleBgCardsProps> = props => {
  const { tabs = [], align, padding } = props;
  const [currentTab, setCurrentTab] = React.useState(0);

  const isHidden = tabs.every(tab => tab.hidden) || tabs.length <= 1;
  const tabsButtons = tabs.filter(tab => !tab.hidden);

  const isMatchesXlBreakpoint = useMedia(
    `(min-width: ${baseTailwindConfig.screens.xl})`,
    true,
  );

  return (
    <section>
      <Container>
        <div className='flex flex-col'>
          {!isHidden && !isMatchesXlBreakpoint && (
            <div className='relative mb-8 w-full'>
              <select
                onChange={e => setCurrentTab(Number(e.target.value))}
                className={clsx(
                  'h-14 w-full px-6 py-4',
                  'rounded-full border-2 border-control-1000',
                  'font-medium leading-loose text-control-1000',
                  'appearance-none',
                )}
              >
                {tabsButtons.map((tab, index) => (
                  <option key={index} value={index}>
                    {tab.name}
                  </option>
                ))}
              </select>
              <IconArrowDown
                width={16}
                height={16}
                className='absolute top-5 ltr:right-5 rtl:left-5'
              />
            </div>
          )}

          {!isHidden && isMatchesXlBreakpoint && (
            <div className='mb-10 flex w-full flex-row justify-center'>
              {tabs.map((tab, index) => (
                <SolidTab
                  key={index}
                  onClick={() => setCurrentTab(index)}
                  label={tab.name}
                  value={tab.name}
                  size={ESolidTabSize.Medium}
                  theme={ESolidTabTheme.Ordinary}
                  isActive={currentTab === index}
                />
              ))}
            </div>
          )}

          {tabs.map((tab, index) => {
            if (currentTab !== index || tab.hidden) {
              return null;
            }

            const cardSize = tab.cards[0].size;

            return (
              <div
                key={index}
                className={gridCVA({
                  size: cardSize,
                  align,
                  padding,
                })}
              >
                {tab.cards.map((card, i) => (
                  <TitleCardBg key={i} {...card} />
                ))}
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default TitleBgCards;
