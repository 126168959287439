import { Button, EButtonVariant } from '../../Button';

export function ActionWrapper({
  button,
  children,
}: {
  button?: React.ComponentProps<typeof Button>;
  children: React.ReactNode;
}): React.ReactNode {
  if (button) {
    return (
      <Button
        {...button}
        variant={EButtonVariant.Text}
        className='text-inherit hover:text-inherit block h-full text-left'
      >
        {children}
      </Button>
    );
  }

  return children;
}
