export enum ETitleCardBgType {
  TypeA = 'typeA',
  TypeB = 'typeB',
  TypeC = 'typeC',
  TypeD = 'typeD',
}

export const enum ETitleCardBgTheme {
  Theme1 = 'Theme1',
  Theme2 = 'Theme2',
  Theme3 = 'Theme3',
  Theme4 = 'Theme4',
  Theme5 = 'Theme5',
  Theme6 = 'Theme6',
  Theme7 = 'Theme7',
  Theme8 = 'Theme8',
}

export enum ETitleCardBgTruncateText {
  None = 'none',
  Line = 'line',
}

export enum ETitleCardBgAlignIcon {
  Top = 'top',
  Center = 'center',
}

export enum ETitleCardBgAlign {
  Left = 'left',
  Center = 'center',
  Right = 'right',
  Justify = 'justify',
}

export enum ETitleCardBgPadding {
  None = 'none',
  Bottom = 'bottom',
  Top = 'top',
}
