import { ETitleCardBgTheme, ETitleCardBgType } from './TitleCardBg.constants';
import { EIconSize } from '../Icon/Icon.constants';
import { ETitlePartsSize } from '../TitlePart';
import { ETitlePartsTheme } from '../TitlePart/TitlePart.constants';

export function defineTitleTheme(theme: ETitleCardBgTheme): ETitlePartsTheme {
  switch (theme) {
    case ETitleCardBgTheme.Theme1:
    case ETitleCardBgTheme.Theme2:
    case ETitleCardBgTheme.Theme4:
    case ETitleCardBgTheme.Theme8:
      return ETitlePartsTheme.Dark;
    default:
      return ETitlePartsTheme.Light;
  }
}

export function defineIconSize(
  type: ETitleCardBgType,
  size: ETitlePartsSize,
): EIconSize {
  if (type === ETitleCardBgType.TypeC) {
    return EIconSize.Xl;
  }

  if (type === ETitleCardBgType.TypeD && size === ETitlePartsSize.S) {
    return EIconSize.L;
  }

  switch (size) {
    case ETitlePartsSize.Xs:
      return EIconSize.Xl;
    default:
      return EIconSize.XxL;
  }
}
