import {
  RichText,
  TitleBgCards,
  toImageProps,
  ETitlePartsSize,
  ETitleCardBgTheme,
  ETitleCardBgType,
  isRichTextContentEmpty,
  ETitleCardBgTruncateText,
  ETitleCardBgAlignIcon,
  ETitleCardBgPadding,
  ETitleCardBgAlign,
} from '@front/shared/ds';
import { Section } from '@shared/master-types';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

import { useAppDispatch } from '../../../store/store.hooks';
import toCardButtons from '../../../utils/toCardButtons';

export type TTitleBgCardsConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'title-card-bg' }
>;

const TitleBgCardsConnected: React.FC<TTitleBgCardsConnectedProps> = props => {
  const { tabs, padding, align } = props;
  const router = useRouter();
  const appDispatch = useAppDispatch();
  const currentLocale = String(router.query.locale || '');

  const nextTabs = useMemo(
    () =>
      tabs.map(tab => ({
        ...tab,
        hidden: Boolean(tab.hidden),
        cards: tab.cards.map(c => ({
          ...c,
          title:
            c.title && !isRichTextContentEmpty(c.title) ? (
              <RichText content={c.title} />
            ) : null,
          description:
            c.description && !isRichTextContentEmpty(c.description) ? (
              <RichText content={c.description} />
            ) : null,
          leftIcon: toImageProps(c.leftIcon),
          rightIcon: toImageProps(c.rightIcon),
          type: c.type as ETitleCardBgType,
          theme: c.theme as ETitleCardBgTheme,
          truncateText: c.truncateText as ETitleCardBgTruncateText,
          alignIcons: c.alignIcons as ETitleCardBgAlignIcon,
          size: c.size as ETitlePartsSize,
          buttons:
            c.actions && toCardButtons(c.actions, currentLocale, appDispatch),
        })),
      })),
    [tabs, currentLocale, appDispatch],
  );
  return (
    <TitleBgCards
      padding={padding as ETitleCardBgPadding}
      align={align as ETitleCardBgAlign}
      tabs={nextTabs}
    />
  );
};

export default TitleBgCardsConnected;
